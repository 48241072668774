import * as React from "react";
import Stack from "@mui/joy/Stack";
import { useOutletContext } from "react-router-dom";
import { TableHover } from "../../components/table";
import TableSheet from "../../components/table-sheet";
import Box from "@mui/joy/Box";
import { useSearchParams } from "react-router-dom";
import { getNumberFromSearchParams } from "../../components/utils";
import Pagination from "../../components/pagination";
import qs from "qs";
import { Account } from "../../types/account";
import { useGetAccountDevices } from "../../query/accounts-query";
import CircularProgress from "@mui/joy/CircularProgress";
import { Typography } from "@mui/joy";

export default function AccountDevices() {
    const { account } = useOutletContext<{ account: Account }>();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const currentPage = getNumberFromSearchParams(searchParams, "page", 1);
    const limit = getNumberFromSearchParams(searchParams, "limit", 10);
    const { data, isLoading } = useGetAccountDevices(account.id, currentPage, limit);
    const totalPages = data?.pagination?.totalPages ?? 1;

    const handlePaginationChange = (page: number, perPage: number) => {
        setSearchParams(
            qs.stringify({
                limit: perPage,
                page,
            })
        );
    };

    const list = data?.list ?? [];

    return (
        <>
            <TableSheet>
                <TableHover>
                    <thead>
                    <tr>
                        <th style={{ width: "100px" }}>IP</th>
                        <th style={{ width: "100px" }}>Platform</th>
                        <th style={{ width: "250px" }}>Device</th>
                        <th style={{ width: "100px" }}>App version</th>
                        <th style={{ width: "100px" }}>Status</th>
                        <th style={{ width: "100px" }}>Push status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        isLoading
                            ? <tr style={{ textAlign: "center" }}>
                                <td colSpan={5}><CircularProgress/></td>
                            </tr>
                            : list.length > 0
                                ? list.map((item) => (
                                    <tr key={item.id}>
                                        <td style={{ width: "100px" }}>
                                            <Typography level="body-xs">{item.session.ip}</Typography>
                                        </td>
                                        <td style={{ width: "100px" }}>
                                            <Typography level="body-xs">{item.os}</Typography>
                                        </td>
                                        <td style={{ width: "250px" }}>
                                            <Typography level="body-xs">{item.manufacturer} {item.model}</Typography>
                                        </td>
                                        <td style={{ width: "100px" }}>
                                            <Typography level="body-xs">{item.appVersion}</Typography>
                                        </td>
                                        <td style={{ width: "100px" }}>
                                            <Typography level="body-xs">{item.status}</Typography>
                                        </td>
                                        <td style={{ width: "100px" }}>
                                            <Typography level="body-xs">{item.pushStatus}</Typography>
                                        </td>
                                    </tr>
                                ))
                                : <tr>
                                    <td colSpan={5} style={{ textAlign: "center" }}>
                                        <Stack
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                            sx={{
                                                paddingTop: "20px",
                                                paddingBottom: "20px"
                                            }}
                                        >
                                            <Typography level="body-md">Devices are not found</Typography>
                                        </Stack>
                                    </td>
                                </tr>
                    }
                    </tbody>
                </TableHover>
            </TableSheet>
            <Box sx={{ py: { md: 2 } }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    limit={limit}
                />
            </Box>
        </>
    );
}
