import client from './api-v1';
import clientV2 from './api';
import { CameraListResponse } from "../types/camera";
import { AccountDevicesResponse } from "../types/account";

export const cameras = async (accountId: number, page: number, limit: number):Promise<CameraListResponse> => {
    return client.get(`account/${accountId}/cameras`, {
        params: {
            page,
            limit
        }
    });
}

export const accountDevices = async (id: number, page: number, limit: number): Promise<AccountDevicesResponse> => {
    return clientV2.get(`/accounts/${id}/devices`, {
        params: {
            page,
            limit
        }
    });
}

export const findCamera = async (accountId: number, cameraId: number) => {
    return client.get(`account/${accountId}/cameras/${cameraId}`);
}

export const attachCamera = async (accountId: number, data: any) => {
    return client.post(`account/${accountId}/camera`, {camera: data});
}
